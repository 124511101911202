import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface IActionButton {
  icon: string;
  onClick: () => void;
};

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {

  @Input() highlighted: boolean = false;
  @Input() separator: boolean = false;
  @Input() bottomPadding: boolean = false;
  @Input() actionButtons: { icon: string; onClick: () => void; }[] = [];
  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClose() {
    this.close.emit();
  }

}
